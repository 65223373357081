import React from "react";

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1366"
      height="229"
      viewBox="0 0 1366 229"
      fill="none"
    >
      <path
        d="M0.000567373 1.14332e-05C397.051 186.041 1105.55 164.062 1366 60.1614L1366 229L0 229L0.000567373 1.14332e-05Z"
        fill="#141450"
      />
    </svg>
  );
};

export default Icon;
