import React, { memo } from "react";
import Select from "react-select";
import ArrowIcon from "icons/ArrowRight";

const FormSelect = memo(({ options, value, setValue, id, label }) => {
  return (
    <div className="form-input">
      <label htmlFor={id}>{label}</label>
      <Select
        id={id}
        options={options}
        value={value}
        onChange={setValue}
        placeholder={null}
        className="form-input form-select"
      />
      {/* {!value && (
        <div className="form-select-wrap">
          <ArrowIcon />
        </div>
      )} */}
    </div>
  );
});

export default FormSelect;
