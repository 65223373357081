import React, { useEffect, useState } from "react";
import ScanIcon from "icons/Scan";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";
import { handleRedirect } from "utils/redirectToScanApp";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";

const ScanButton = ({ top = 650 }) => {
  const { user_info } = useSelector(userSelect);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const checkScrollPosition = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > top && scroll < scrollTop) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setScroll(scrollTop);
    };

    document.addEventListener("scroll", checkScrollPosition);

    return () => document.removeEventListener("scroll", checkScrollPosition);
  });

  return (
    <>
      <button
        onClick={() => {
          if (user_info.id) handleRedirect(user_info.msisdn);
          else navigate(ROUTES_PATH.REGISTRATION);
        }}
        className={`primary-button primary-button--light scan-button 
      ${visible ? "scan-button--visible" : ""}`}
      >
        <ScanIcon />
        <span>Скан чека</span>
      </button>
    </>
  );
};

export default ScanButton;
