import React, { memo } from "react";
import { Link } from "react-router-dom";

const PrimaryButton = memo(
  ({
    href,
    callback,
    text,
    disabled,
    long,
    outlined,
    small,
    light,
    component,
  }) => {
    const classNames = `primary-button 
    ${small ? "primary-button--small" : ""}   
    ${outlined ? "primary-button--outlined" : ""} 
    ${long ? "primary-button--long" : ""}
    ${light ? "primary-button--light" : ""}
    ${disabled ? "primary-button--disabled" : ""}`;

    return (
      <>
        {href ? (
          <Link className={classNames} to={href}>
            {text}
          </Link>
        ) : (
          <button
            className={classNames}
            onClick={(e) => {
              e.preventDefault();
              callback();
            }}
            disabled={disabled}
          >
            {text}
            {component && component}
          </button>
        )}
      </>
    );
  }
);

export default PrimaryButton;
