import React from "react";

const Icon = () => {
  return (
    <svg
      width={173}
      height={112}
      viewBox="0 0 173 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.918 84.4802H150.214L147.332 95.2495L146.269 94.5244C142.341 91.8295 141.401 84.9945 142.746 79.939C144.107 74.8578 148.098 70.1211 153.167 67.5753L154.979 66.665L152.525 75.8503H164.224L166.617 66.9068L167.68 67.6319C171.547 70.2857 173.083 75.1818 171.686 80.407C170.361 85.3494 165.924 92.0043 160.783 94.5861L158.97 95.4964L161.918 84.4802Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.524 94.7011C117.685 94.7011 114.434 93.2144 112.365 90.524C110.204 87.7049 109.602 83.9445 110.677 79.9423C112.041 74.8598 116.039 70.122 121.118 67.5756L122.934 66.665L119.25 80.4104C118.828 81.9948 119.044 83.4506 119.857 84.5155C120.624 85.5186 121.864 86.0691 123.351 86.0691C126.7 86.0691 130.163 83.3478 131.084 79.9886V79.9783L131.105 79.9166L134.588 66.912L135.654 67.6373C139.533 70.2917 141.072 75.189 139.672 80.4156C137.557 88.2914 129.417 94.7011 121.524 94.7011Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6144 93.6369C29.6484 94.3849 27.6308 94.7769 25.6905 94.7769C21.851 94.7769 18.5982 93.2862 16.5292 90.5885C14.3624 87.7618 13.7653 83.9912 14.8359 79.9782C16.1946 74.8819 20.1988 70.1313 25.2736 67.578L27.0904 66.665L23.4053 80.4476C22.9832 82.0363 23.1994 83.496 24.0126 84.5638C24.7795 85.5696 26.0199 86.1215 27.5073 86.1215C30.8733 86.1215 34.3474 83.3671 35.2584 79.9833L38.753 66.9075L39.8184 67.6348C43.694 70.2964 45.2329 75.2018 43.8329 80.4373L38.9538 98.6867C37.5899 103.788 33.5908 108.534 28.5109 111.087L26.6941 112L31.6041 93.6266L31.6144 93.6369Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43823 94.6997C4.24106 94.1804 2.55844 92.9567 1.44699 91.0492C-0.353969 87.9591 -0.477464 83.3214 1.09709 77.9948C2.3166 73.8558 3.4898 69.3364 4.52407 65.3414C5.25989 62.5032 5.8928 60.0559 6.4228 58.2357L6.536 57.8398L6.90648 57.665C11.6302 55.4284 14.7381 53.1147 17.6248 49.6905L17.8975 49.3665H38.7732L38.2381 50.6314C36.3651 55.0634 29.4854 59.7628 20.4446 59.7628C19.2354 59.7628 18.0107 59.6805 16.7912 59.5109C15.3093 65.0277 13.7862 70.7092 12.2631 76.4009C10.6679 82.3497 9.07278 88.2985 7.5291 94.0467L7.29755 94.9002L6.43823 94.6997Z"
        fill="white"
      />
      <path
        d="M74.123 69.8574C72.042 67.1558 68.7756 65.671 64.9087 65.671C56.9678 65.671 48.7785 72.0898 46.6561 79.9883C45.5742 84.0046 46.1799 87.7734 48.3592 90.5936C50.4402 93.29 53.7118 94.78 57.5735 94.78C59.5251 94.78 61.5543 94.3881 63.5317 93.6406L58.5933 112L60.4206 111.087C65.4988 108.556 69.3968 104.014 70.8514 98.9303L75.8313 80.4575C76.9132 76.4412 76.3075 72.6775 74.1282 69.8522L74.123 69.8574ZM68.0871 80.2255C67.0725 83.9943 63.1849 87.0464 59.4008 87.0464C55.6168 87.0464 53.3753 83.9943 54.3899 80.2255C55.4045 76.4567 59.2921 73.4045 63.071 73.4045C66.8499 73.4045 69.0966 76.4567 68.082 80.2255H68.0871Z"
        fill="white"
      />
      <path
        d="M105.963 69.7739C103.895 67.0723 100.638 65.5874 96.8052 65.5874C94.8708 65.5874 92.8489 65.9793 90.8836 66.7268L95.7917 48.3726L93.9756 49.2851C88.9338 51.8217 85.0598 56.3588 83.609 61.4371L78.6494 79.9615C77.5948 83.9572 78.2018 87.7053 80.3523 90.5152C82.4205 93.2116 85.672 94.7016 89.51 94.7016C97.402 94.7016 105.541 88.2776 107.65 80.3843C108.72 76.368 108.119 72.6043 105.963 69.779V69.7739ZM99.9641 80.142C98.9609 83.9108 95.092 86.9629 91.3312 86.9629C87.5704 86.9629 85.3427 83.9108 86.3511 80.142C87.3595 76.3731 91.2232 73.321 94.984 73.321C98.7448 73.321 100.972 76.3731 99.9641 80.142Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9372 48.173L101.006 48.8748C102.449 49.8158 103.247 50.9648 103.377 52.2905C103.643 54.9522 101.094 57.4529 99.4057 59.1061C99.0773 59.4232 98.7959 59.704 98.5823 59.9327L96.0391 62.6881L99.9372 48.173Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.428 10.5535C125.84 10.6185 127.017 11.1766 127.922 12.2217C129.389 13.9146 130.002 16.7541 129.65 20.2143C129.379 22.9025 129.18 25.8195 129.007 28.3977C128.883 30.2294 128.777 31.8087 128.661 32.9901L128.638 33.2469L128.43 33.3974C125.782 35.3208 124.138 37.1046 122.758 39.5461L122.628 39.7771L109.782 42.1952L109.964 41.3549C110.604 38.4106 114.293 34.7219 119.856 33.6747C120.6 33.5346 121.363 33.4434 122.133 33.4065C122.406 29.84 122.685 26.1674 122.963 22.4886C123.256 18.6431 123.548 14.7977 123.832 11.0817L123.876 10.5296L124.428 10.5535Z"
        fill="#F0E6B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.651 29.8338C152.077 28.5419 151.129 27.6483 149.826 27.1853C147.716 26.435 144.848 26.8962 141.753 28.4821C139.347 29.712 136.702 30.9575 134.363 32.0567C132.702 32.8382 131.269 33.5112 130.211 34.0481L129.98 34.1637L129.915 34.4119C129.086 37.5777 128.022 39.7582 126.25 41.9312L126.082 42.1366L128.5 54.9826L129.216 54.5068C131.727 52.8409 133.822 48.063 132.774 42.4997C132.634 41.7556 132.442 41.0115 132.196 40.2807C135.419 38.7298 138.739 37.1344 142.065 35.5378C145.541 33.8672 149.017 32.1965 152.375 30.5807L152.874 30.3394L152.651 29.8338Z"
        fill="#F0E6B4"
      />
    </svg>
  );
};

export default Icon;
