import React from "react";

const SectionTitle = ({ text, center, blue, extraClass, underlined }) => {
  return (
    <h2
      className={`section-title ${extraClass ? extraClass : ""} 
      ${center ? "section-title--centered" : ""} 
      ${blue ? "section-title--blue" : ""} 
      ${underlined ? "section-title--underlined" : ""}`}
      dangerouslySetInnerHTML={{ __html: text }}
    ></h2>
  );
};

export default SectionTitle;
