import ComplimentHeader from "layout/compliment/ComplimentHeader";
import React, { useEffect, useState } from "react";
import ComplimentContent from "layout/compliment/ComplimentContent";
import { TransactionApi } from "utils/api";
import { useLocation, useParams } from "react-router-dom";
import { userSelect } from "redux/selectors/user";
import { useSelector } from "react-redux";

const UserCompliment = () => {
  const { user_info, auth_key } = useSelector(userSelect);
  const { state } = useLocation();

  const [bonus, setBonus] = useState(null);

  const [isActivated, setIsActivated] = useState(
    bonus?.tr_activated === 1 ? true : false
  );
  const { compliment_id } = useParams();

  useEffect(() => {
    updateBonusState();
  }, [compliment_id]);

  const updateBonusState = () => {
    TransactionApi.getTransactionBonus({
      id: compliment_id,
      user_id: user_info.id ? user_info.id : null,
      auth_key,
    }).then((res) => {
      setBonus(res);
      if (res.tr_activated === 1) setIsActivated(true);
    });
  };

  return (
    <main className="main">
      {bonus && (
        <>
          <ComplimentHeader
            prevPath={state?.prevPath}
            bonus={bonus}
            isActivated={isActivated}
          />
          <ComplimentContent
            payed
            bonus={bonus}
            isActivated={isActivated}
            updateBonusState={updateBonusState}
          />
        </>
      )}
    </main>
  );
};

export default UserCompliment;
