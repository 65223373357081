import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import Bonuses from "./Bonuses";

const ComplimentCard = memo(
  ({
    image,
    title,
    price,
    place_title,
    id,
    userCompliment,
    activated,
    href = `/compliment/${id}`,
    tr_num,
    activated_time,
  }) => {
    const dateOptions = {
      minute: "2-digit",
      hour: "2-digit",
      month: "long",
      day: "2-digit",
    };

    const createdDate = new Date(activated_time).toLocaleString(
      [],
      dateOptions
    );
    const { pathname } = useLocation();

    return (
      <Link to={href} state={{ prevPath: pathname }}>
        <div className="compliment-card">
          <div className="compliment-card__image">
            <img src={image} alt="Изображение комплимента" />
            <Bonuses bonusesCount={price} />
            {userCompliment && activated && (
              <>
                <div className="compliment-card__overlay"></div>
                <div className="compliment-card__tr-content">
                  <p>Номер транзакции</p>
                  <div className="compliment-card__tr-num">{tr_num}</div>
                  <p>{createdDate}</p>
                </div>
              </>
            )}
          </div>
          <div className="compliment-card__content">
            <h4 className="compliment-card__title">{title}</h4>
            <div className="compliment-card__bottom-row">
              <h5 className="compliment-card__shop">{place_title}</h5>
              {userCompliment &&
                (activated ? (
                  <span className="compliment-card__activation">
                    Активировано
                  </span>
                ) : (
                  <span className="compliment-card__activation compliment-card__activation--disabled">
                    Ожидает активации
                  </span>
                ))}
            </div>
          </div>
        </div>
      </Link>
    );
  }
);

export default ComplimentCard;
