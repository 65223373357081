import React, { memo } from "react";
import { IMaskInput } from "react-imask";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";

const RegistrationForm = memo(
  ({
    phone,
    setPhone,
    name,
    setName,
    primaryAction,
    secondaryAction,
    dataSending,
    email,
    setEmail,
  }) => {
    return (
      <form className="registration-form">
        <IMaskInput
          mask={"+{7}(000)000-00-00"}
          radix="."
          value={phone}
          unmask={true}
          onAccept={(value, mask) => setPhone(value)}
          placeholder="Телефон"
          className={"primary-input primary-input--first"}
        />
        <IMaskInput
          mask={/^[a-zЁёА-я]+\s?[a-zЁёА-я]*$/i}
          placeholder="Ваше имя"
          onAccept={(value, mask) => setName(value)}
          value={name}
          className={"primary-input primary-input--first"}
        />
        <IMaskInput
          mask={String}
          radix="."
          value={email}
          unmask={true}
          onAccept={(value, mask) => setEmail(value)}
          placeholder="E-mail"
          className={"primary-input "}
        />
        <PrimaryButton
          disabled={dataSending}
          text={"Зарегистрироваться"}
          callback={primaryAction}
        />
        <SecondaryButton
          text={"У меня уже есть код"}
          callback={secondaryAction}
        />
      </form>
    );
  }
);

export default RegistrationForm;
