import GoBackButton from "components/GoBackButton";
import SectionTitle from "components/titles/SectionTitle";
import React, { useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMaskInput } from "react-imask";
import PrimaryButton from "components/buttons/PrimaryButton";
import { InviteFriendApi } from "utils/api";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";
import { popupProvider } from "contextProviders/popupProvider";

const InviteFriendContent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, services } = useSelector((state) => state);
  const { user_info, auth_key } = user;
  const { service } = services;
  const { setPopupContent, setPopupOpen } = useContext(popupProvider);

  const [phone, setPhone] = useState("");
  const [dataSending, setDataSending] = useState(false);

  const goBackCallback = useCallback(() => navigate(`/account/${id}`), []);

  const handleSubmit = () => {
    setDataSending(true);

    InviteFriendApi.checkInvite({
      user_id: user_info.id,
      msisdn: phone.slice(1),
      auth_key,
    })
      .then((res) => {
        handleSendInvite(res.message);
      })
      .catch((e) => {
        setPopupContent({
          error: e.response.data.error,
        });
        setPopupOpen(true);
        setDataSending(false);
      });
  };

  const handleSendInvite = (message) => {
    InviteFriendApi.sendInvite({
      user_id: user_info.id,
      msisdn: phone.slice(1),
      auth_key,
      message,
    })
      .then(() => {
        setPopupContent({
          title: "Отправлено",
          children: <p className="text">Приглашение другу отправлено</p>,
          acceptButton: {
            text: "Закрыть",
            callback: () => setPopupOpen(false),
          },
        });
        setPopupOpen(true);
      })
      .finally(() => setDataSending(false));
  };

  return (
    <section className="invite-friend">
      <div className="container">
        <GoBackButton callback={goBackCallback} />

        <div className="invite-friend__content">
          <SectionTitle
            blue
            text={"Пригласи друга"}
            extraClass="invite-friend__title"
          />

          <p className="invite-friend__text">
            Чтобы получить +{service.bonus_friend} баллов, введите номер
            телефона друга, он получит SMS приглашение. После первой покупки
            друга на Ваш счет будут зачислены дополнительные бонусы!
          </p>

          <IMaskInput
            mask={"+{7}(000)000-00-00"}
            radix="."
            value={phone}
            unmask={true}
            onAccept={(value, mask) => setPhone(value)}
            placeholder="Телефон"
            className={"primary-input"}
          />

          {/* <div className="invite-friend__contacts">
            <SecondaryButton text="Выбрать из контактов" />
          </div>

          <span className="info-text">
            Нажимая на «выбрать из контактов», Вы даете приложению доступ к
            списку контактов, для выбора контактов, по которым собираетесь
            отправить приглашение. Полные правила программы смотрите здесь.
          </span> */}

          <div className="invite-friend__submit">
            <PrimaryButton
              disabled={dataSending}
              long
              text={"Пригласить"}
              callback={handleSubmit}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InviteFriendContent;
