import React from "react";

const BecomeMemberFrame = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={375}
      height={190}
      viewBox="0 0 375 190"
      fill="none"
    >
      <path
        d="M0.000155758 1.52588e-05C109 186.219 303.5 164.219 375 60.2188L375 189.219L0 189.219L0.000155758 1.52588e-05Z"
        fill="#141450"
      />
    </svg>
  );
};

export default BecomeMemberFrame;
