import React, { memo } from "react";
import GoBackIcon from "icons/GoBack";
import NavigateIcon from "icons/Navigate";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";

const ComplimentHeader = memo(({ isActivated, bonus, news, prevPath }) => {
  const navigate = useNavigate();

  const dateOptions = {
    minute: "2-digit",
    hour: "2-digit",
    month: "long",
    day: "2-digit",
  };

  const createdDate = new Date(bonus?.activated_time).toLocaleString(
    [],
    dateOptions
  );
  const currentDate = new Date().toLocaleString([], dateOptions);

  const goBackCallback = () => {
    if (prevPath) {
      navigate(prevPath);
    } else {
      navigate(ROUTES_PATH.HOME);
    }
  };

  return (
    <div className="compliment-header">
      <div className="compliment-header__go-back" onClick={goBackCallback}>
        <GoBackIcon />
      </div>

      {/* <div className="compliment-header__navigate">
        <NavigateIcon />
      </div> */}

      {isActivated && (
        <div className="compliment-header__content">
          <h4 className="compliment-header__title">Номер транзакции</h4>
          <div className="compliment-header__number">{bonus.tr_num}</div>
          <div className="compliment-header__date">{createdDate}</div>
          <div className="compliment-header__current-date">
            <span>Текущее время: </span> {currentDate}
          </div>
        </div>
      )}

      <div className="compliment-header__img">
        <img
          src={bonus?.image || news?.gallery[0]?.url}
          alt="Изображение комплимента"
        />
      </div>
    </div>
  );
});

export default ComplimentHeader;
