import React, { memo } from "react";
import PrimaryButton from "./buttons/PrimaryButton";
import SecondaryButton from "./buttons/SecondaryButton";

const Popup = memo(({ open, setOpen, popupContent }) => {
  const { title, icon, children, acceptButton, cancelButton, error } =
    popupContent;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div className="popup" onClick={handleClose}>
          <div className="popup__content" onClick={(e) => e.stopPropagation()}>
            {error ? (
              <>
                <div className="popup__title section-title">Ошибка</div>
                <p className="text text--centered text--error">{error}</p>

                <div className="popup__button-row">
                  <PrimaryButton
                    text={"Закрыть"}
                    callback={handleClose}
                    small
                  />
                </div>
              </>
            ) : (
              <>
                {icon && <div className="popup__icon">{icon}</div>}
                {title && (
                  <div className="popup__title section-title">{title}</div>
                )}
                {children}

                <div className="popup__button-row">
                  {acceptButton && (
                    <PrimaryButton
                      text={acceptButton.text}
                      callback={acceptButton.callback}
                      small
                      disabled={acceptButton.disabled}
                    />
                  )}

                  {cancelButton && (
                    <SecondaryButton
                      small
                      text={cancelButton.text}
                      callback={handleClose}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
});

export default Popup;
