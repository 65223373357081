import SectionTitle from "components/titles/SectionTitle";
import GoBackButton from "components/GoBackButton";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ComplimentCard from "components/ComplimentCard";
import { TransactionApi } from "utils/api";
import { userSelect } from "redux/selectors/user";
import { useSelector } from "react-redux";

const UserCompliments = () => {
  const { user_info, auth_key } = useSelector(userSelect);
  const navigate = useNavigate();
  const { id } = useParams();

  const [bonuses, setBonuses] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  const fetchCompliments = () => {
    TransactionApi.getTransactionBonuses({
      auth_key,
      user_id: user_info.id,
      page,
    })
      .then((data) => {
        if (data.length === 0) setIsLastPage(true);
        setBonuses([...bonuses, ...data]);
        setPage((prev) => prev + 1);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    setIsLastPage(false);
    fetchCompliments();
  }, []);

  useEffect(() => {
    if (fetching) {
      fetchCompliments();
    }
  }, [fetching]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  });

  const goBackCallback = () => {
    navigate(`/account/${id}`);
  };

  const handleScroll = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      !isLastPage
    ) {
      setFetching(true);
    }
  };

  return (
    <main className="main">
      <section className="user-compliments">
        <div className="container">
          <GoBackButton callback={goBackCallback} />

          <div className="user-compliments__content">
            <SectionTitle
              extraClass={"user-compliments__title"}
              blue
              text={"Мои комплименты"}
            />

            <ul className="compliments__list">
              {bonuses.length > 0
                ? bonuses.map((bonus, key) => (
                    <ComplimentCard
                      {...bonus}
                      price={Math.abs(bonus.bonus)}
                      activated={bonus.activated === 1 ? true : false}
                      key={key}
                      userCompliment
                      href={`/account/${id}/compliment/${bonus.id}`}
                    />
                  ))
                : !fetching && <p className="text">Комплиментов нет.</p>}
            </ul>

            {fetching && <p className="text">Загрузка...</p>}
          </div>
        </div>
      </section>
    </main>
  );
};

export default UserCompliments;
