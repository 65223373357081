import React from "react";

const Icon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67231 55.3333C8.67231 57.1771 10.1654 58.6667 12.0113 58.6667H28.706V35.3333H8.67231V55.3333ZM55.4175 18.6667H51.0205C52.2684 16.2792 52.5867 13.4011 51.0163 10.1875C49.5117 7.10314 46.298 5.33172 42.8443 5.33334C38.4828 5.33497 35.6656 7.55418 32.0449 12.4479C28.4242 7.55418 25.607 5.33497 21.2455 5.33334C17.7918 5.33172 14.5801 7.10314 13.0735 10.1875C11.5031 13.4011 11.8214 16.2813 13.0703 18.6667H8.67231C6.82859 18.6667 5.33337 20.1594 5.33337 22V28.6667C5.33337 30.5 6.8359 31.9969 8.57841 31.9969H55.3236C57.16 31.9969 58.6625 30.5 58.6625 28.6667V22C58.7564 20.1563 57.2643 18.6667 55.4175 18.6667ZM21.3916 18.6667C19.0867 18.6667 17.2179 16.8011 17.2179 14.5C17.2179 12.199 19.0867 10.3333 21.3916 10.3333C23.468 10.3333 25.0018 10.6771 30.3754 18.6667H21.3916ZM42.6982 18.6667H33.7144C39.088 10.6771 40.6207 10.3333 42.6971 10.3333C45.0021 10.3333 46.8708 12.199 46.8708 14.5C46.8708 16.8011 45.0042 18.6667 42.6982 18.6667ZM35.3838 58.6667H52.0785C53.9243 58.6667 55.4175 57.1771 55.4175 55.3333L55.4175 35.3333H35.4777L35.3838 58.6667Z"
        fill="#F15922"
      />
    </svg>
  );
};

export default Icon;
