import React, { useContext, useState } from "react";
import Bonuses from "components/Bonuses";
import { IMaskInput } from "react-imask";
import { popupProvider } from "contextProviders/popupProvider";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";
import { BonusesApi } from "utils/api";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";

const BuyComplimentForm = ({ bonuses, callbackSuccessAction, bonus_id }) => {
  const { user_info } = useSelector(userSelect);
  const { setPopupContent, setPopupOpen } = useContext(popupProvider);
  const [code, setCode] = useState("");
  const [dataSending, setDataSending] = useState(false);

  const handleActivate = () => {
    setDataSending(true);

    BonusesApi.bonusActivate({
      user_id: user_info.id,
      msisdn: user_info.msisdn,
      code,
      bonus_id,
    })
      .then((res) => {
        callbackSuccessAction(res.id, true);
        setPopupContent({
          title: "Активировано",
          children: (
            <p className="text text--centered">
              Вы успешно активировали комплимент
            </p>
          ),
          acceptButton: {
            text: "Закрыть",
            callback: () => setPopupOpen(false),
          },
        });
        setPopupOpen(true);
      })
      .catch((e) => {
        setPopupContent({
          error: e.response.data.error,
        });
        setPopupOpen(true);
      })
      .finally(() => setDataSending(false));
  };

  return (
    <form className="buy-compliment-form">
      <Bonuses bonusesCount={bonuses} label={"Бонусов"} />
      <div className="compliment-content__popup-message">
        Запросите код комплимента у администратора ТЦ на 1-ом этаже
      </div>
      <IMaskInput
        mask={String}
        radix="."
        value={code}
        unmask={true}
        onAccept={(value, mask) => setCode(value)}
        placeholder="Код подтверждения"
        className={"primary-input primary-input--small"}
      />

      <div className="popup__button-row">
        <PrimaryButton
          text={"Готово"}
          callback={handleActivate}
          small
          disabled={dataSending}
        />

        <SecondaryButton
          small
          text={"Позже"}
          callback={() => setPopupOpen(false)}
        />
      </div>
    </form>
  );
};

export default BuyComplimentForm;
