import React from "react";

const Icon = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0562 2.69427C2.54489 2.39242 3.06774 2.30986 3.61085 2.45307C4.49075 2.68499 5.34729 3.49709 6.02229 4.73862C6.5475 5.70261 7.14949 6.72671 7.68212 7.63147C8.06033 8.27443 8.38675 8.82857 8.61884 9.25136L8.6697 9.34295L8.6345 9.44105C8.18707 10.6932 8.04419 11.6685 8.1409 12.8039L8.15011 12.9112L4.39457 16.6668L4.2633 16.343C3.80293 15.2087 4.19517 13.1256 5.82161 11.4992C6.03915 11.2816 6.27426 11.0761 6.52418 10.8872C5.79829 9.62815 5.05021 8.33206 4.30027 7.03411C3.51705 5.67696 2.73383 4.31981 1.97742 3.00799L1.86554 2.81279L2.0562 2.69427Z"
        fill="#F0E6B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9436 2.69427C15.4549 2.39242 14.932 2.30986 14.3889 2.45307C13.509 2.68499 12.6525 3.49709 11.9775 4.73862C11.4523 5.70261 10.8503 6.72671 10.3176 7.63147C9.93943 8.27443 9.613 8.82857 9.38091 9.25136L9.33005 9.34295L9.36526 9.44105C9.81268 10.6932 9.95557 11.6685 9.85885 12.8039L9.84964 12.9112L13.6052 16.6668L13.7365 16.343C14.1968 15.2087 13.8046 13.1256 12.1781 11.4992C11.9606 11.2816 11.7255 11.0761 11.4756 10.8872C12.2015 9.62815 12.9496 8.33206 13.6995 7.03411C14.4827 5.67696 15.2659 4.31981 16.0223 3.00799L16.1342 2.81279L15.9436 2.69427Z"
        fill="#F0E6B4"
      />
    </svg>
  );
};

export default Icon;
