import GoBackButton from "components/GoBackButton";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SectionTitle from "components/titles/SectionTitle";
import SectionSubtitle from "components/titles/SectionSubtitle";
import ProfileForm from "components/forms/ProfileForm";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";

const ProfileContent = () => {
  const { user_info } = useSelector(userSelect);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("/img/avatar.png");
  const navigate = useNavigate();

  const goBackCallback = () => {
    navigate(`/account/${user_info.id}`);
  };

  const handleImageUpload = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage(file);
      setImagePreview(reader.result);
      debugger;
    };

    reader.readAsDataURL(file);
  };

  return (
    <section className="profile">
      <div className="container">
        <GoBackButton callback={goBackCallback} />

        <div className="profile__content">
          <div className="profile__top">
            <SectionTitle blue text={"Профиль"} extraClass={"profile__title"} />
            <SectionSubtitle
              text={"Расскажите о вас подробнее"}
              extraClass={"profile__subtitle"}
            />

            <div className="profile__add-photo">
              <div className="profile__avatar">
                <img
                  src={user_info.avatar ? user_info.avatar : imagePreview}
                  alt="Аватар профиля"
                />
              </div>
              <input
                type="file"
                id="avatar"
                onChange={(e) => handleImageUpload(e)}
                max={1}
              />
              <label htmlFor="avatar">
                {user_info.avatar ? "Изменить фото" : "Добавить фото"}
              </label>
            </div>
          </div>

          <div className="profile__bottom">
            <ProfileForm image={image} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileContent;
