import Input from "components/formElements/Input";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import TextArea from "components/formElements/TextArea";
import PrimaryButton from "components/buttons/PrimaryButton";
import FormSelect from "components/formElements/FormSelect";
import { api_key, ContactApi } from "utils/api";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";
import { popupProvider } from "contextProviders/popupProvider";

const ContactForm = () => {
  const { user_info } = useSelector(userSelect);
  const { setPopupOpen, setPopupContent } = useContext(popupProvider);
  const fileInputRef = useRef();

  const [dataSending, setDataSending] = useState(false);
  const [email, setEmail] = useState(user_info.email ? user_info.email : "");
  const [message, setMessage] = useState("Сообщение");
  const [types, setTypes] = useState([]);
  const [typeValue, setTypeValue] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

  useEffect(() => getTypesData(), []);

  const getTypesData = () => {
    ContactApi.getMessageTypes({ user_id: user_info.id }).then((data) => {
      const dataArray = [];

      data.forEach((item) => {
        const newObj = {
          label: item.title,
          value: item.id,
        };

        dataArray.push(newObj);
      });

      setTypes(dataArray);

      setTypeValue(dataArray[0]);
    });
  };

  const handleSubmit = useCallback(() => {
    setDataSending(true);

    const formData = new FormData();

    formData.append("api_key", api_key);
    formData.append("mall_id", 1);
    formData.append("email", email);
    formData.append("request", message);
    formData.append("type_id", typeValue.value);
    if (user_info?.id) formData.append("user_id", user_info.id);
    if (images) formData.append("image", images);

    ContactApi.sendMessage(formData)
      .then(() => {
        setEmail(user_info.email ? user_info.email : "");
        setMessage("Сообщение");
        getTypesData();
        setImages([]);
        setImagesPreview([]);

        setPopupContent({
          title: "Отправлено",
          children: (
            <p className="text text--centered">
              Ваше сообщение успешно отправлено!
            </p>
          ),
          acceptButton: {
            text: "Закрыть",
            callback: () => setPopupOpen(false),
          },
        });
        setPopupOpen(true);
      })
      .catch((e) => {
        console.log(e);
        setPopupContent({
          error: e.response.data.error,
        });
        setPopupOpen(true);
      })
      .finally(() => setDataSending(false));
  }, [typeValue, message, email, user_info, images]);

  const handleImageUpload = useCallback(
    (e) => {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        setImages([...images, file]);
        setImagesPreview([...imagesPreview, reader.result]);
        fileInputRef.current.value = null;
      };

      reader.readAsDataURL(file);
    },
    [images, imagesPreview]
  );

  const handleImageRemove = useCallback(
    (id) => {
      setImages(images.filter((image, key) => key !== id));
      setImagesPreview(imagesPreview.filter((image, key) => key !== id));
    },
    [images, imagesPreview]
  );

  return (
    <form className="contact-form">
      <FormSelect
        label="Тип"
        id="contact-type"
        options={types}
        value={typeValue}
        setValue={setTypeValue}
      />
      <Input
        mask={String}
        label="E-mail для ответа"
        id="email"
        value={email}
        setValue={setEmail}
        placeholder="Ваш e-mail"
      />

      <TextArea
        label="Ваше сообщение"
        id="message"
        value={message}
        setValue={setMessage}
      />

      <div className="contact-form__button-row">
        <div
          className={`contact-form__attach ${
            images.length > 0 ? "contact-form__attach--attached" : ""
          }`}
        >
          {imagesPreview.length > 0 &&
            imagesPreview.map((image, key) => (
              <div key={key} className="contact-form__preview">
                <span onClick={() => handleImageRemove(key)}>x</span>
                <img src={image} alt="Превью картинки" />
              </div>
            ))}
          <input
            ref={fileInputRef}
            onChange={handleImageUpload}
            type="file"
            id="photo"
          />
          {imagesPreview.length !== 3 && (
            <label htmlFor="photo">
              <span>Прикрепить фото/чек</span>
            </label>
          )}
        </div>

        <PrimaryButton
          disabled={dataSending || !email || !typeValue || !message}
          text="Отправить"
          callback={handleSubmit}
        />
      </div>
    </form>
  );
};

export default ContactForm;
